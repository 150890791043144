import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import { getBaseURL } from "@/utils"

const path = "/v1/issue-form-templates"

export default {
  /**
   * This action will load issue form templates.
   * @param {*} context it is the store.
   * @param {*} payload it is the filter criteria
   */
  async loadIssueFormTemplates(context, payload) {
    context.commit("setLoadingIssueFormTemplates", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getIssueFormTemplateResponse = await httpClient.get(undefined, {
      params: payload
    })

    if (getIssueFormTemplateResponse.status === HTTP_STATUS_CODE.OK) {
      if (payload) {
        context.commit("updateIssueFormTemplates", getIssueFormTemplateResponse.data)
      } else {
        context.commit("setIssueFormTemplates", getIssueFormTemplateResponse.data)
      }
    }

    context.commit("setLoadingIssueFormTemplates", false)
  },

  /**
   * This action will add new issue form templates to issue customisation.
   * @param {*} context is the store.
   * @param {*} payload contains the details of issue type and formTemplateId to be added.
   */
  async addIssueFormTemplates(context, payload) {
    context.commit("setAddingIssueFormTemplates", true)
    context.commit("setIssueFormTemplatesAdded", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const postIssueFormTemplatesAddResponse = await httpClient.post(undefined, payload)

    if (postIssueFormTemplatesAddResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("setIssueFormTemplatesAdded", true)
      context.commit("updateIssueFormTemplates", postIssueFormTemplatesAddResponse.data)
    }
    context.commit("setAddingIssueFormTemplates", false)
  },

  /**
   * This action updates issue formTemplate.
   * @param {*} context is the store.
   * @param {*} issueFormTemplate contains the details to be updated.
   */
  async updateIssueFormTemplate(context, issueFormTemplate) {
    const id = issueFormTemplate.id
    delete issueFormTemplate.id
    const propertiesToBeUpdated = Object.keys(issueFormTemplate)
    for (const property of propertiesToBeUpdated) {
      if (issueFormTemplate[property] === undefined) {
        issueFormTemplate[property] = null
      }
    }
    context.commit("setUpdatingIssueFormTemplate", propertiesToBeUpdated)
    context.commit("resetIssueFormTemplateUpdated", propertiesToBeUpdated)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const updateFieldResponse = await httpClient.put(`/${id}`, issueFormTemplate)

    if (updateFieldResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("updateIssueFormTemplates", [{
        id,
        ...issueFormTemplate
      }])
      context.commit("setIssueFormTemplateUpdated", propertiesToBeUpdated)
    }

    context.commit("resetUpdatingIssueFormTemplate", propertiesToBeUpdated)
  },

  /**
   * This action removes issue form templates.
   * @param {*} context is the store.
   * @param {*} payload contains the id of the issue form template to be removed.
   */
  async removeIssueFormTemplate(context, payload) {
    context.commit("setRemovingIssueFormTemplate", true)
    context.commit("setIssueFormTemplateRemoved", false)
    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const deleteIssueFormTemplateResponse = await httpClient.delete(`/${payload}`)

    if (deleteIssueFormTemplateResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("issueFormTemplateRemove", payload)
      context.commit("setIssueFormTemplateRemoved", true)
    }

    context.commit("setRemovingIssueFormTemplate", false)
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setIssueFormTemplates", new Array())
    context.commit("setLoadingIssueFormTemplates", undefined),
    context.commit("setAddingIssueFormTemplates", undefined),
    context.commit("setIssueFormTemplatesAdded", undefined),
    context.commit("resetUpdatingIssueFormTemplate")
    context.commit("resetIssueFormTemplateUpdated")
    context.commit("setRemovingIssueFormTemplate", undefined)
    context.commit("setIssueFormTemplateRemoved", undefined)
  }
}