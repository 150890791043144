export default {
  setIsLoadingIssueComments(state, loadingIssueComments) {
    state.loadingIssueComments = loadingIssueComments
  },
  updateIssueComments: (state, issueComments) => {
    for (const issueComment of issueComments) {
      const index = state.issueComments.findIndex(stateIssueComment => stateIssueComment?.id === issueComment.id)
      if (index >= 0) {
        state.issueComments.splice(index, 1, { ...state.issueComments[index], ...issueComment })
      } else {
        state.issueComments.push(issueComment)
      }
    }
  },
  setIssueComments: (state, issueComments) => {
    state.issueComments = issueComments
  },
  setAddingComment: (state, addingComment) => {
    state.addingComment = addingComment
  },
  setCommentAdded: (state, commentAdded) => {
    state.commentAdded = commentAdded
  },
  setCommentRemoved: (state, commentRemoved) => {
    state.commentRemoved = commentRemoved
  },
  setRemovingComment: (state, removingComment) => {
    state.removingComment = removingComment
  },
  removeCommentFromIssueComment: (state, { commentId }) => {
    const index = state.issueComments.findIndex(comment => comment.id === commentId)
    state.issueComments.splice(index, 1)
  }
}