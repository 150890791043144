export default {
  loadingIssueFormTemplates: undefined,
  issueFormTemplates       : new Array(),
  addingIssueFormTemplates : false,
  issueFormTemplatesAdded  : false,
  removingIssueFormTemplate: false,
  issueFormTemplateRemoved : false,
  updatingIssueFormTemplate: {
    multiInstance: false,
    autoAdded    : false
  },
  issueFormTemplateUpdated: {
    multiInstance: false,
    autoAdded    : false
  }
}