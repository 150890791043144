/**
 * @file It contains all the vuex kpis module state variables.
 */
export default {
  loadingKpis               : undefined,
  kpis                      : new Array(),
  issuesWithUnreadMessages  : new Array(),
  issuesWithUnansweredReport: new Array(),
  issueWithDueDate          : new Array(),
  issuesWhichAreOverdue     : new Array()
}