/**
 * @file It contains decision constants that are used to pass to themis-decision component.
 */
export default {
  REMOVE_GROUP: {
    pCardProps: {
      class: ["pa-2"]
    },
    pActions: [{
      text       : "187",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "188",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "error",
        block  : true,
        loading: false
      }
    }]
  },
  CLOSE_ISSUE: {
    pCardProps: {
      class: ["pa-2"]
    },
    pActions: [{
      text       : "302",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        color  : "primary",
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "303",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "error",
        block  : true,
        loading: false
      }
    }]
  },
  SET_DATA_RETENTION_PERIOD: {
    pCardProps: {
      class: ["pa-2"]
    },
    pActions: [{
      text       : "336",
      class      : ["col-6", "col-md-2" ],
      value      : "no",
      buttonProps: {
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "1291",
      class      : ["col-6", "col-md-4", "ml-3"],
      value      : "yes",
      buttonProps: {
        color  : "primary",
        block  : true,
        loading: false
      }
    }]
  },
  DISCARD_DRAFT_MESSAGE: {
    pCardProps: {
      class: ["pa-2"]
    },
    pActions: [{
      text       : "321",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        color  : "primary",
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "322",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "primary",
        block  : true,
        loading: false
      }
    }]
  },
  SEND_MESSAGE_TO_REPORTER: {
    pCardProps: {
      class: ["pa-2"]
    },
    pActions: [{
      text       : "324",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        color  : "primary",
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "325",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "primary",
        block  : true,
        loading: false
      }
    }]
  },
  CLOSE_REPORT: {
    pCardProps: {
      class: ["pa-2"]
    },
    pActions: [{
      text       : "332",
      class      : ["col-6", "col-md-4", "px-4"],
      value      : "no",
      buttonProps: {
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "333",
      class      : ["col-6", "col-md-4", "mr-4"],
      value      : "yes",
      buttonProps: {
        color  : "error darken-2",
        block  : true,
        loading: false
      }
    }]
  },
  ANONYMISE_REPORT: {
    pCardProps: {
      class: ["pa-2"]
    },
    pActions: [{
      text       : "336",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "337",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "error darken-2",
        block  : true,
        loading: false
      }
    }]
  },
  CHANNEL_DISABLE: {
    pCardProps: {
      class: ["pa-2"]
    },
    pActions: [{
      text       : "340",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        color  : "primary",
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "341",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "error",
        block  : true,
        loading: false
      }
    }]
  },
  REMOVE_DOMAIN_ACCESS: {
    pCardProps: {
      class: ["pa-2"]
    },
    pActions: [{
      text       : "354",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        color  : "primary",
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "355",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "error",
        block  : true,
        loading: false
      }
    }]
  },
  ARCHIVE_DOMAIN: {
    pCardProps: {
      class: ["pa-2"]
    },
    pActions: [{
      text       : "358",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        color  : "primary",
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "359",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "error",
        block  : true,
        loading: false
      }
    }]
  },
  REMOVE_ISSUE_ACCESS: {
    pCardProps: {
      class: ["pa-2"]
    },
    pActions: [{
      text       : "360",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        color  : "primary",
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "361",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "error",
        block  : true,
        loading: false
      }
    }]
  },
  DELETE_USER: {
    pCardProps: {
      class: ["pa-2", "pt-8"]
    },
    pActions: [{
      text       : "171",
      class      : ["col-4", "pl-4"],
      value      : "no",
      buttonProps: {
        color  : "info",
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "172",
      class      : ["col-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "error",
        block  : true,
        loading: false
      }
    }]
  },
  DISABLE_MFA: {
    pCardProps: {
      class: ["pa-2", "pt-8"]
    },
    pActions: [{
      text       : "278",
      class      : ["col-4", "pl-4"],
      value      : "no",
      buttonProps: {
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "279",
      class      : ["col-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "error",
        block  : true,
        loading: false
      }
    }]
  },
  EXCEPTION: {
    pCardProps: {
      class: ["pa-2"]
    },
    pActions: [{
      text       : "118",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        color  : "error",
        block  : true,
        loading: false
      }
    }, {
      text       : "119",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "primary",
        block  : true,
        loading: false
      }
    }]
  },
  DISABLE_SELF_MFA: {
    pCardProps: {
      class: ["pa-2", "pt-8"]
    },
    pActions: [{
      text       : "274",
      class      : ["col-4", "pl-4"],
      value      : "no",
      buttonProps: {
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "275",
      class      : ["col-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "error",
        block  : true,
        loading: false
      }
    }]
  },
  REMOVE_ACCESS: {
    pCardProps: {
      class: ["pa-2"]
    },
    pActions: [{
      text       : "50",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        color  : "primary",
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "51",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "error",
        block  : true,
        loading: false
      }
    }]
  },
  ADD_ISSUE: {
    pCardProps: {
      class: ["pa-2", "pb-4"]
    },
    pActions: [{
      text       : "1080",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "1079",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "primary",
        block  : true,
        loading: false
      }
    }]
  },
  REQUEST_HUMAN_TRANSLATION: {
    pCardProps: {
      class: ["pa-2"]
    },
    pActions: [{
      text       : "404",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        color  : "primary",
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "405",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "primary",
        block  : true,
        loading: false
      }
    }]
  },
  REDO_HUMAN_TRANSLATION: {
    pCardProps: {
      class: ["pa-2"]
    },
    pActions: [{
      text       : "408",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        color  : "primary",
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "409",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "primary",
        block  : true,
        loading: false
      }
    }]
  },
  REMOVE_COMMENT: {
    pCardProps: {
      class: ["pa-2"]
    },
    pActions: [{
      text       : "601",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "600",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "error",
        block  : true,
        loading: false
      }
    }]
  },
  CHANGE_ISSUE_DOMAIN: {
    pCardProps: {
      class: ["pa-2"]
    },
    pActions: [{
      text       : "601",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "665",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "error",
        block  : true,
        loading: false
      }
    }]
  },
  REMOVE_DOCUMENT: {
    pCardProps: {
      class: ["pa-2", "py-7"]
    },
    pActions: [{
      text       : "601",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "600",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "error",
        block  : true,
        loading: false
      }
    }]
  },
  DOWNLOAD_EXPORT: {
    pCardProps: {
      class: ["pa-3"]
    },
    pActions: [{
      text       : "332",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "726",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "primary",
        block  : true,
        loading: false
      }
    }]
  },
  DOWNLOAD_ANALYTICS_EXPORT: {
    pCardProps: {
      class: ["pa-3"]
    },
    pActions: [{
      text       : "767",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "766",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "primary",
        block  : true,
        loading: false
      }
    }]
  },
  REMOVE_CHANNEL_FORM_TEMPLATE: {
    pCardProps: {
      class: ["pa-4"]
    },
    pActions: [{
      text       : "332",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "361",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "error darken-2",
        block  : true,
        loading: false
      }
    }]
  },
  REMOVE_FORM_FIELD: {
    pCardProps: {
      class: ["pa-4"]
    },
    pActions: [{
      text       : "332",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "913",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "error darken-2",
        block  : true,
        loading: false
      }
    }]
  },
  REMOVE_ISSUE_FIELD: {
    pCardProps: {
      class: ["pa-4"]
    },
    pActions: [{
      text       : "332",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "913",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "error darken-2",
        block  : true,
        loading: false
      }
    }]
  },
  REMOVE_ALL_FIELD_CONDITIONS: {
    pCardProps: {
      class: ["pa-4"]
    },
    pActions: [{
      text       : "332",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "1841",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "error darken-2",
        block  : true,
        loading: false
      }
    }]
  },
  DISABLE_OPTION_LIST_TRANSLATION: {
    pCardProps: {
      class: ["pa-4"]
    },
    pActions: [{
      text       : "332",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "1229",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "error darken-2",
        block  : true,
        loading: false
      }
    }]
  },
  ENABLE_DEFAULT_SSO_LOGIN: {
    pCardProps: {
      class: ["pa-4"]
    },
    pActions: [{
      text       : "887",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "886",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "primary",
        block  : true,
        loading: false
      }
    }]
  },
  ENFORCE_MFA_REQUIRED: {
    pCardProps: {
      class: ["pa-4"]
    },
    pActions: [{
      text       : "2037",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "2038",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "primary",
        block  : true,
        loading: false
      }
    }]
  },
  ENFORCE_MFA_OPTIONAL: {
    pCardProps: {
      class: ["pa-4"]
    },
    pActions: [{
      text       : "2045",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "2046",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "error darken-2",
        block  : true,
        loading: false
      }
    }]
  },
  REMOVE_FORM_INSTANCE: {
    pCardProps: {
      class: ["pa-4"]
    },
    pActions: [{
      text       : "1039",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "1040",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "error darken-2",
        block  : true,
        loading: false
      }
    }]
  },
  REMOVE_ISSUE_LINK: {
    pCardProps: {
      class: ["pa-4"]
    },
    pActions: [
      {
        text       : "332",
        class      : ["col-6", "col-md-4", "pl-4"],
        value      : "no",
        buttonProps: {
          block  : true,
          text   : true,
          loading: false
        }
      },
      {
        text       : "1251",
        class      : ["col-6", "col-md-4", "pl-4"],
        value      : "yes",
        buttonProps: {
          color  : "error darken-2",
          block  : true,
          loading: false
        }
      }
    ]
  },
  REMOVE_AUTOMATION: {
    pCardProps: {
      class: ["pa-4"]
    },
    pActions: [{
      text       : "30",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "31",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "error darken-2",
        block  : true,
        loading: false
      }
    }]
  },
  REMOVE_SCREEN_ITEM: {
    pCardProps: {
      class: ["pa-4"]
    },
    pActions: [{
      text       : "332",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "1496",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "error darken-2",
        block  : true,
        loading: false
      }
    }]
  },
  DELETE_TRANSITION: {
    pCardProps: {
      class: ["pa-4"]
    },
    pActions: [{
      text       : "30",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "1542",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "error darken-2",
        block  : true,
        loading: false
      }
    }]
  },
  REMOVE_WORKFLOW_STATUS: {
    pCardProps: {
      class: ["pa-4"]
    },
    pActions: [{
      text       : "1548",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "1547",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "error darken-2",
        block  : true,
        loading: false
      }
    }]
  },
  UPDATE_WORKFLOW: {
    pCardProps: {
      class: ["pa-2"]
    },
    pActions: [{
      text       : "1572",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        class  : "font-weight-bold",
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "1573",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "error darken-2",
        block  : true,
        loading: false
      }
    }]
  },
  RE_PUBLISH_TERMS_OF_USE: {
    pCardProps: {
      class: ["pa-2"]
    },
    pActions: [{
      text       : "1651",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        class  : "font-weight-bold",
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "1652",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "primary",
        block  : true,
        loading: false
      }
    }]
  },
  DISABLE_PRIVACY_POLICY: {
    pCardProps: {
      class: ["pa-4"]
    },
    pActions: [{
      text       : "1766",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        class  : "font-weight-bold",
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "1765",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "error darken-2",
        block  : true,
        loading: false
      }
    }]
  },
  REMOVE_TERMS_OF_USE: {
    pCardProps: {
      class: ["pa-4"]
    },
    pActions: [{
      text       : "1656",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "1657",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "primary",
        block  : true,
        loading: false
      }
    }]
  },
  REMOVE_REPLY_TEMPLATE: {
    pCardProps: {
      class: ["pa-4"]
    },
    pActions: [{
      text       : "1745",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "1744",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "error darken-2",
        block  : true,
        loading: false
      }
    }]
  },
  ENABLE_CONSENT: {
    pCardProps: {
      class: ["pa-4"]
    },
    pActions: [{
      text       : "1775",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "1739",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "primary",
        block  : true,
        loading: false
      }
    }]
  },
  DISABLE_CONSENT: {
    pCardProps: {
      class: ["pa-4"]
    },
    pActions: [{
      text       : "1775",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "1774",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "error darken-2",
        block  : true,
        loading: false
      }
    }]
  },
  REMOVE_FILTER: {
    pCardProps: {
      class: ["pa-4"]
    },
    pActions: [{
      text       : "2213",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "2214",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "error darken-2",
        block  : true,
        loading: false
      }
    }]
  },
  UNSHARE_FILTER: {
    pCardProps: {
      class: ["pa-4"]
    },
    pActions: [{
      text       : "2201",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "2202",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "error darken-2",
        block  : true,
        loading: false
      }
    }]
  },
  REMOVE_ISSUE_FORM_TEMPLATE: {
    pCardProps: {
      class: ["pa-4"]
    },
    pActions: [{
      text       : "332",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "2171",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "error darken-2",
        block  : true,
        loading: false
      }
    }]
  }
}