/**
 * @file It contains vuex kpis module getter methods to access store variables.
 */
export default {
  isLoadingKpis           : state => state.loadingKpis,
  kpis                    : state => state.kpis,
  issuesWithUnreadMessages: state => state.kpis.filter(
    kpi => kpi.hasUnreadMessages === true
  )?.map(kpi => kpi.id),
  issuesWithUnansweredReport: state => state.kpis.filter(
    kpi => kpi.hasUnansweredReport === true
  )?.map(kpi => kpi.id),
  issueWithDueDate: state => state.kpis.filter(
    kpi => kpi.dueSoon === true
  )?.map(kpi => kpi.id),
  issuesWhichAreOverdue: state => state.kpis.filter(
    kpi => kpi.overdue === true
  )?.map(kpi => kpi.id)
}